import React, { useContext, useState, useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getShopifyImage } from 'gatsby-source-shopify';
import styled from 'styled-components';
import {
  fontSize,
  brandFonts,
  minBreakpointQuery,
  maxBreakpointQuery,
} from '../styles';
import { StoreContext } from '../context/StoreContext';
import { formatPrice } from '../utils';
import ProductQuantity from './ProductQuantity';
import { BodyLarge, Typography } from './typography/Typography';

const StyledCartItem = styled.div`
  display: grid;
  grid-template-columns: 113px repeat(2, 1fr);
  row-gap: 8px;
  column-gap: 16px;
  font-family: ${brandFonts.tertiary};
  padding: 28px 0;

  ${minBreakpointQuery.medium`
    grid-template-columns: 113px 1.5fr 1fr 1fr 78px;
    padding: 24px 0;
    align-items: center;
  `}

  ${minBreakpointQuery.large`
    grid-template-columns: 120px 1.5fr 1fr 1fr 78px;
    column-gap: 24px;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  max-width: 100%;

  ${maxBreakpointQuery.medium`
    grid-row: 1 / 5;
  `}
`;

const StyledHeading = styled(Typography)`
  text-transform: uppercase;

  ${maxBreakpointQuery.medium`
    grid-column: 2 / 4;
  `}
`;

const StyledPrice = styled(BodyLarge)`
  text-transform: uppercase;
  ${maxBreakpointQuery.medium`
    grid-column: 2 / 4;
  `}
`;

const StyledProductQuantity = styled(ProductQuantity)`
  justify-self: start;

  ${maxBreakpointQuery.medium`
    grid-row: 4;
  `}

  ${minBreakpointQuery.medium`
    grid-row: 1;
    grid-column: 3;
  `}
`;

const StyledRemove = styled.button`
  padding: 0;
  ${fontSize(13)};
  background: none;
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-family: ${brandFonts.tertiary};
  justify-self: end;
  margin-bottom: 8px;

  ${maxBreakpointQuery.medium`
    grid-row: 4;
    align-self: end;
  `}

  &:before {
    content: '✕';
    ${fontSize(12)};
    margin-right: 8px;

    ${minBreakpointQuery.mlarge`
      ${fontSize(13)};
    `}
  }

  span {
    text-decoration: underline;
  }

  ${minBreakpointQuery.mlarge`
    ${fontSize(15)};
  `}
`;

const CartItem = ({
  product: { id, price, title, quantity: currentQuantity, properties },
}) => {
  const { removeProductFromCheckout, updateProductInCheckout } =
    useContext(StoreContext);

  const productImageSrcProperty = properties.find(
    item => item.name === 'imageSrc'
  );

  const imageData = getShopifyImage({
    image: { originalSrc: productImageSrcProperty.value },
    layout: 'constrained',
    width: 120,
    height: 120,
  });

  const [quantity, setQuantity] = useState(currentQuantity);

  useEffect(() => {
    setQuantity(currentQuantity);
  }, [currentQuantity]);

  const changeQuantity = async value => {
    const changedQuantity = parseInt(value);

    await updateProductInCheckout(id, changedQuantity);
    setQuantity(changedQuantity);
  };

  return (
    <StyledCartItem>
      <StyledImage image={imageData} alt={title} />
      <StyledHeading as="h2" variant="body-large">
        {title}
      </StyledHeading>
      <StyledPrice>{formatPrice(price)}</StyledPrice>
      <StyledProductQuantity
        quantity={quantity}
        maxQuantity={10}
        setQuantity={changeQuantity}
      />
      <StyledRemove onClick={() => removeProductFromCheckout(id)}>
        <span>Remove</span>
      </StyledRemove>
    </StyledCartItem>
  );
};

export default CartItem;
