import React, { useState, useContext, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  brandFonts,
  fontSize,
  maxBreakpointQuery,
  minBreakpointQuery,
  standardColours,
  visuallyHidden,
} from '../styles';
import { Button, Container, Link } from './ui';
import { StoreContext } from '../context/StoreContext';
import { formatPrice } from '../utils';
import CartItem from './CartItem';
import ProductInfo from './ProductInfo';
import FeaturedProduct from './FeaturedProduct';
import { BodySmall, H1 } from './typography/Typography';

const StyledCart = styled.section`
  margin-top: 40px;
  margin-bottom: 120px;

  ${minBreakpointQuery.mlarge`
    margin-top: 50px;
    margin-bottom: 140px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 60px;
    margin-bottom: 160px;
  `}

  ${minBreakpointQuery.xlarge`
    margin-top: 80px;
    margin-bottom: 180px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-top: 100px;
    margin-bottom: 200px;
  `}
`;

const StyledHeading = styled(H1)``;

const StyledInner = styled.div`
  ${minBreakpointQuery.medium`
    display: grid;
    grid-template-columns: 1fr 300px;
    column-gap: 80px;
    margin-top: 60px;
  `}

  ${minBreakpointQuery.large`
    column-gap: 100px;
    grid-template-columns: 1fr 350px;
  `}

  ${minBreakpointQuery.xlarge`
    column-gap: 120px;
  `}

  ${minBreakpointQuery.xxlarge`
    column-gap: 140px;
  `}
`;

const StyledGrid = styled.div``;

const StyledGridHeadings = styled.div`
  grid-template-columns: 113px 1.5fr 1fr 1fr 78px;
  column-gap: 16px;
  display: grid;

  ${maxBreakpointQuery.medium`
    display: none;
  `}

  ${minBreakpointQuery.large`
    grid-template-columns: 120px 1.5fr 1fr 1fr 78px;
    column-gap: 24px;
  `}

  span {
    font-family: ${brandFonts.tertiary};
    text-transform: uppercase;
    ${fontSize(18)};
    letter-spacing: 0.02em;

    &:first-child {
      grid-column: 1 / 3;
    }
  }
`;

const StyledItems = styled.ul`
  margin-top: 20px;
  display: grid;
  border-bottom: 1px solid ${standardColours.transparentBlack(0.3)};

  ${minBreakpointQuery.medium`
    margin-top: 18px;
  `}
`;

const StyledItem = styled.li`
  border-top: 1px solid ${standardColours.transparentBlack(0.3)};

  &:first-child {
    border: none;

    ${minBreakpointQuery.medium`
      border-top: 1px solid ${standardColours.transparentBlack(0.3)};
    `}
  }
`;

const StyledSummary = styled.div`
  ${minBreakpointQuery.medium`
    grid-row: 1/3;
    grid-column: 2/3;
  `}
`;

const StyledSummaryLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  text-transform: uppercase;
  font-family: ${brandFonts.tertiary};
  ${fontSize(14)};
  letter-spacing: 0.02em;

  ${minBreakpointQuery.medium`
    ${fontSize(16)}
  `}

  ${minBreakpointQuery.large`
    ${fontSize(18)}
  `}
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 12px;

  ${minBreakpointQuery.large`
    margin-top: 24px;
  `}
`;

const StyledLink = styled(Link)`
  width: 100%;
`;

const StyledInfo = styled(ProductInfo)`
  margin-top: 60px;

  ${minBreakpointQuery.large`
    margin-top: 80px;
  `}
`;

const StyledRelatedProduct = styled(FeaturedProduct)`
  margin-bottom: 0;
`;

const StyledText = styled.p`
  margin-top: 10px;
`;

const Cart = () => {
  const {
    datoCmsProductSitewide: { companyIconUsps },
    allShopifyProduct,
  } = useStaticQuery(graphql`
    query CartQuery {
      datoCmsProductSitewide {
        companyIconUsps {
          icon {
            url
            alt
          }
          text
        }
      }
      allShopifyProduct {
        nodes {
          ...ProductCardFragment
        }
      }
    }
  `);

  //todo: uncomment related product after removing ghost product
  const { checkout, addDiscountToCheckout, removeDiscountFromCheckout } =
    useContext(StoreContext);

  const {
    line_items: lineItems,
    subtotal_price: subtotalPrice,
    webUrl,
    discountApplications,
  } = checkout;

  const [hasSubmittedCode, setHasSubmittedCode] = useState(false);

  const [isValidCode, setIsValidCode] = useState(false);

  const [relatedProduct, setRelatedProduct] = useState();

  const lineItemsValue = lineItems?.reduce(
    (acc, item) => acc + item?.quantity * item?.price,
    0
  );

  const subtotalWithoutAppliedDiscount = formatPrice(
    parseFloat(lineItemsValue)
  );

  const checkCode = () => {
    if (discountApplications?.length > 0) {
      setIsValidCode(true);
    } else {
      setIsValidCode(false);
    }
  };

  useEffect(() => {
    checkCode();

    const relatedProduct = allShopifyProduct.nodes.find(shopifyProduct => {
      const productInLineItems = lineItems?.find(model => {
        return model.title === shopifyProduct.heading;
      });

      return !productInLineItems && shopifyProduct.status === 'ACTIVE';
    });

    setRelatedProduct(relatedProduct);
  }, [discountApplications]);

  const handleSubmit = async e => {
    e.preventDefault();

    if (isValidCode) {
      setHasSubmittedCode(false);
      setIsValidCode(false);
      await removeDiscountFromCheckout(checkout.id);
    } else {
      await addDiscountToCheckout(e.target.name.value);
      setHasSubmittedCode(true);
    }

    checkCode();
  };

  return (
    <StyledCart>
      <Container>
        <StyledHeading>Your Cart</StyledHeading>
        {lineItems?.length > 0 ? (
          <StyledInner>
            <StyledGrid>
              <StyledGridHeadings>
                <span>Product</span>
                <span>Qty</span>
                <span>Price</span>
              </StyledGridHeadings>
              <StyledItems>
                {lineItems.map(item => (
                  <StyledItem key={item.id}>
                    <CartItem product={item} />
                  </StyledItem>
                ))}
              </StyledItems>
            </StyledGrid>
            <StyledSummary>
              <StyledSummaryLine>
                <p>Delivery</p>
                <p>N/A</p>
              </StyledSummaryLine>
              <StyledSummaryLine>
                <p>Subtotal</p>
                <p>{subtotalWithoutAppliedDiscount}</p>
              </StyledSummaryLine>
              <StyledLink to="/checkout">
                <StyledButton>Checkout</StyledButton>
              </StyledLink>

              <StyledInfo iconUsps={companyIconUsps} isCart={true} />
            </StyledSummary>
            {/* {relatedProduct && (
              <StyledRelatedProduct {...relatedProduct} isCart={true} />
            )} */}
          </StyledInner>
        ) : (
          <StyledText>Your cart is empty</StyledText>
        )}
      </Container>
    </StyledCart>
  );
};

export default Cart;
