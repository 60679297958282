import React from 'react';
import Layout from '../components/Layout';
import Cart from '../components/Cart';

const CartPage = () => {
  return (
    <Layout noIndex={true}>
      <main>
        <Cart />
      </main>
    </Layout>
  );
};

export default CartPage;
