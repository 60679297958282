import React from 'react';
import styled from 'styled-components';
import { fontSize, visuallyHidden } from '../styles';
import { AnimatedBlock } from './ui';
import { H2, Typography } from './typography/Typography';

const StyledProductInfo = styled(AnimatedBlock)``;

const StyledHeading = styled(H2)`
  ${visuallyHidden()};
`;

const StyledIconUsps = styled.ul`
  margin-bottom: 50px;
`;

const StyledIconUsp = styled(props => (
  <Typography as="p" variant="body-small" {...props} />
))`
  position: relative;
  display: flex;
  margin-top: 18px;
  padding-left: 38px;
  ${fontSize(15)};
  line-height: 1.4;

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
  }

  &:first-child {
    margin-top: 0;
  }
`;

const ProductInfo = ({ iconUsps, isCart, ...props }) => (
  <StyledProductInfo {...props}>
    <StyledHeading>Product Information</StyledHeading>
    {iconUsps.length > 0 && (
      <StyledIconUsps>
        {iconUsps.map(({ icon, text }, id) => (
          <StyledIconUsp key={id}>
            <img src={icon.url} alt={icon.alt} loading="lazy" />
            {text}
          </StyledIconUsp>
        ))}
      </StyledIconUsps>
    )}
  </StyledProductInfo>
);

export default ProductInfo;
