import React from 'react';
import styled from 'styled-components';
import { standardColours, brandColours, visuallyHidden } from '../styles';

const StyledProductQuantity = styled.label`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 16px;
    height: 0;
    width: 0;
    border-top: 6px solid
      ${({ alt }) =>
        alt ? standardColours.transparentWhite(0.5) : brandColours.primary};
    border-right: 4px solid ${standardColours.transparent};
    border-left: 4px solid ${standardColours.transparent};
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

const StyledText = styled.span`
  ${visuallyHidden()};
`;

const StyledSelect = styled.select`
  padding: 8px 28px 8px 16px;
  height: 100%;
  width: 60px;
  line-height: 1;
  background: none;
  border: 1px solid
    ${({ alt }) =>
      alt
        ? standardColours.transparentWhite(0.5)
        : standardColours.transparentBlack(0.2)};
  appearance: none;
`;

const ProductQuantity = ({
  quantity,
  maxQuantity,
  setQuantity,
  alt,
  ...props
}) => (
  <StyledProductQuantity alt={alt} {...props}>
    <StyledText>Quantity</StyledText>
    <StyledSelect
      name="quantity"
      alt={alt}
      onChange={e => setQuantity(e.currentTarget.value)}
    >
      {[...Array(maxQuantity || 10)].map((item, i) => {
        const num = i + 1;

        return (
          <option key={i} value={num} selected={num === quantity}>
            {num}
          </option>
        );
      })}
    </StyledSelect>
  </StyledProductQuantity>
);

export default ProductQuantity;
