import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { standardColours } from '../styles';
import { Button } from './ui';
import ProductQuantity from './ProductQuantity';
import { formatPrice } from '../utils';
import { StoreContext } from '../context/StoreContext';
import { IS_DEV_STORE } from '../lib/constants';

const StyledProductAddToCart = styled.form`
  display: flex;
  gap: 8px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  width: 100%;

  ${({ isProductSummaryStrip }) => {
    if (isProductSummaryStrip) {
      return css`
        border: 1px solid ${standardColours.transparentWhite(0.5)};
      `;
    }
  }}
`;

const ProductAddToCart = ({
  variants,
  id: gid,
  alt,
  isProductSummaryStrip,
  ...props
}) => {
  const variant = variants[0];

  const { addProductToCheckout, toggleCartOpen, checkout } =
    useContext(StoreContext);

  const [quantity, setQuantity] = useState(1);
  const [addingToCart, setAddingToCart] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    setAddingToCart(true);

    //gid://shopify/Product/7511276978316
    //gid://shopify/Product/7738619723916 //super premium
    const productToAdd = IS_DEV_STORE
      ? 'gid://shopify/Product/7511276978316'
      : gid;

    const regex = /(\d+)$/; // Regex to capture the number at the end
    const id = Number(gid.match(regex)[0]);

    const updatedCheckout = await addProductToCheckout(productToAdd, quantity);

    const recentlyAddedItem = updatedCheckout.line_items.find(
      item => item.product_id === id
    );

    //track add to cart event
    //todo:make it a function
    window._learnq.push([
      'track',
      'Added to Cart',
      {
        cartSubtotalPrice:
          updatedCheckout.subtotal_price ?? checkout.subtotal_price,
        cartTotalPrice: updatedCheckout.total_price ?? checkout.total_price,
        cartAppliedDiscount:
          updatedCheckout.applied_discount ?? checkout.applied_discount,
        cartCurrency: updatedCheckout.currency ?? checkout.currency,
        cartTaxLines: updatedCheckout.tax_lines ?? checkout.tax_lines,
        cartTotalTax: updatedCheckout.total_tax ?? checkout.total_tax,
        cartAllItems: updatedCheckout.line_items ?? checkout.line_items,
        recentlyAddedItem,
      },
    ]);

    setAddingToCart(false);

    setAddedToCart(true);

    setTimeout(() => {
      toggleCartOpen();
      setAddedToCart(false);
      setQuantity(1);
    }, '1000');
  };

  return (
    <StyledProductAddToCart onSubmit={e => handleSubmit(e)} {...props}>
      {!alt && (
        <ProductQuantity
          quantity={quantity}
          maxQuantity={variant.inventoryQuantity}
          setQuantity={setQuantity}
          alt={isProductSummaryStrip}
        />
      )}
      <StyledButton
        disabled={addingToCart || addedToCart}
        alt={isProductSummaryStrip}
        isProductSummaryStrip={isProductSummaryStrip}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      >
        {addingToCart
          ? 'Adding...'
          : addedToCart
          ? 'Added'
          : `${alt ? `${formatPrice(variant.price)} - ` : ''}Add to Cart`}
      </StyledButton>
    </StyledProductAddToCart>
  );
};

export default ProductAddToCart;
